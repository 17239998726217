/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import {
  DEFAULT_FETCHING_NEWS_OPTIONS,
  useFetchNewsArticles,
} from "@/apis/queryFunctions/news";
import useTrans from "@/hooks/useTrans";
import { Grid } from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";
import Container from "../Layout/Container/Container";
import NewCard from "../NewCard";
import HomeTitle from "../Typography/HomeTitle";

export default function HomeNews() {
  const trans = useTrans();
  const { locale } = useRouter();
  const { data: news } = useFetchNewsArticles({
    ...DEFAULT_FETCHING_NEWS_OPTIONS,
    condition: {
      ...DEFAULT_FETCHING_NEWS_OPTIONS.condition,
      ngon_ngu: locale,
    },
  });

  return (
    <section>
      <Container>
        <div>
          <HomeTitle href="/tin-tuc">{trans.homeNews.title}</HomeTitle>

          <Grid>
            {news
              ? news.slice(0, 4).map((item) => (
                  <Grid.Col xs={12} sm={6} lg={3} key={item._id}>
                    <NewCard item={item} />
                  </Grid.Col>
                ))
              : Array(4)
                  .fill(undefined)
                  .map((item, i) => (
                    <Grid.Col xs={12} sm={6} lg={3} key={i}>
                      <NewCard item={item} isLoading />
                    </Grid.Col>
                  ))}
          </Grid>
        </div>
      </Container>
    </section>
  );
}

// const HomeNews = () => {

// };

// export default HomeNews;
