import { useFetchCategories } from "@/apis/queryFunctions/categoryFunctions";
import useTrans from "@/hooks/useTrans";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { Box, Grid, Text } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import HomeTitle from "../Typography/HomeTitle";

export default function HomeLiked() {
  const { data } = useFetchCategories({ condition: { highlight: true } });

  if (data?.length > 3) {
    data.splice(3, data.length - 3);
  }

  const trans = useTrans();
  const { locale } = useRouter();

  return (
    <Box component="section">
      <HomeTitle>{trans?.homeLiked.title}</HomeTitle>
      <Box component="section">
        <Grid gutter="xs" justify="center">
          {Boolean(data) &&
            data?.map?.((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid.Col
                key={index}
                xs={6}
                sm={4}
                sx={{
                  width: "100%",
                  cursor: "pointer",
                  transition: "1s",
                  // filter: "blur(1px)",
                  // "&:hover": {
                  //   backgroundColor: "#00723B",
                  //   opacity: 0.6,
                  //   // filter: "opacity(.5)",
                  //   filter: "alpha(opacity=50)",
                  // },
                }}
              >
                <Link href={`/danh-muc-san-pham/${item?.slug}`}>
                  <Box
                    sx={{
                      position: "relative",
                      aspectRatio: "1.2",
                      width: "100%",
                      borderRadius: 8,
                      overflow: "hidden",
                      // backgroundColor: "#00723B",
                      "&:hover": {
                        // backgroundColor: "#00723B",
                        // opacity: 0.5,
                        // filter: "opacity(.5)",
                        // filter: "alpha(opacity=0)",
                      },
                    }}
                  >
                    <Image
                      src={appendImageUrlFromAPI({ src: item?.logo })}
                      layout="fill"
                    />
                    <Box
                      sx={{
                        position: "relative",
                        aspectRatio: "1.2",
                        width: "100%",
                        borderRadius: 8,
                        overflow: "hidden",
                        transition: "1s",

                        "&:hover": {
                          backgroundColor: "#00723B",

                          opacity: 0.5,
                        },
                      }}
                    />
                    <Box
                      p={8}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                      }}
                    >
                      <Text size={30} align="center" color="#fff" sx={{}}>
                        {locale === "vi"
                          ? item?.ten_danh_muc
                          : item?.ten_danh_muc_eng}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              </Grid.Col>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}
