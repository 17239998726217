import Slider from "@ant-design/react-slick";
import { Box, createStyles, Image, Skeleton } from "@mantine/core";

import {
  HOME_BANNER_OPTIONS,
  useFetchBanners,
} from "@/apis/queryFunctions/banner";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import { getHrefLinkOfBanner } from "@/utils/getHrefLinkOfBanner";
import Link from "next/link";
import React from "react";
import { NextArrow, PrevArrow } from "./SliderArrows";

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    maxHeight: "calc(100vh - 100px)",
    aspectRatio: "2.75",

    position: "relative",
    // transition: "0.3s",
    // opacity: "0",
  },
  sliderItem: {
    width: "100%",
    // maxHeight: "calc(100vh - 100px)",
    objectFit: "cover",
    height: "calc(100vh - 100px)",

    position: "relative",
    "@media (max-width: 36em)": {
      height: "calc(100vw / 2.5)",
      aspectRatio: "2.5",
    },
  },
  dotsClass: {
    position: "absolute",
    bottom: "10px",
    width: "100%",
    listStyle: "none",
    textAlign: "center",
    padding: 0,
    margin: 0,

    // "& li button:before": {
    //   fontSize: "10px !important",
    // },
    // "& li.slick-active button:before": {
    //   color: "#EBBC1D",
    // },

    "& li": {
      position: "relative",
      display: "inline-block",
      width: "20px",
      height: "20px",
      margin: "0 5px",
      padding: "0",
      cursor: "pointer",
    },

    "& li button": {
      fontSize: 0,
      lineHeight: 0,
      display: "block",
      width: "20px",
      height: "20px",
      padding: "5px",
      cursor: "pointer",
      color: "transparent",
      border: 0,
      outline: "none",
      background: "transparent",
    },

    "& li button:after": {
      // content: "'⚫'",
      fontFamily: "slick",
      fontSize: "10px",
      lineHeight: "20px",
      position: "absolute",
      top: 0,
      left: 0,
      width: "20px",
      height: "20px",
      content: "'•'",
      textAlign: "center",
      opacity: 0.25,
      color: theme.colors.second[0],
      // "-webkit-font-smoothing": "antialiased",
    },
    "& li.slick-active button:after": {
      opacity: ".75",
      color: "#EBBC1D",
    },
  },
}));

export default function HomeBanner() {
  const { classes } = useStyles();
  // let dragging = false;

  const { data: sliders } = useFetchBanners(HOME_BANNER_OPTIONS);

  const settings = {
    dots: true,
    // infinite: true,
    initialSlide: 0,

    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // appendDots: (dots) => <ul className={classes.dotsClass}>{dots}</ul>,
    dotsClass: classes.dotsClass,
    arrows: true,
    swipeToSlide: true,
    draggable: true,
    nextArrow: <NextArrow slider />,
    prevArrow: <PrevArrow slider />,
    useTransform: false,
  };

  return (
    <Skeleton visible={!sliders || sliders.length === 0}>
      <div className={classes.container} id="sliders">
        <Slider {...settings}>
          {sliders &&
            sliders.map((item) => (
              <Link
                href={getHrefLinkOfBanner(item)}
                key={item._id}
                scroll={false}
                // passHref
              >
                <picture>
                  <img
                    src={appendImageUrlFromAPI({
                      src: item.hinh_anh,
                      size: "xl",
                    })}
                    alt={item.ten_banner}
                    loading="lazy"
                    className={classes.sliderItem}
                  />
                </picture>
              </Link>
            ))}
        </Slider>
      </div>
    </Skeleton>
  );
}
