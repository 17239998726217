import { useFetchEcompage } from "@/apis/queryFunctions/ecompagesFunctions";
import sanitizeDOMData from "@/utils/sanitizeDOMData";
import { Box } from "@mantine/core";
import { useRouter } from "next/router";
import React from "react";
import HomeTitle from "../Typography/HomeTitle";

function Slogan() {
  const {locale} = useRouter()
  const { data } = useFetchEcompage("du-lieu-chung", locale);

  const slogan = data?.add_on_data?.[0] || {};

  return (
    <Box component="section">
      <HomeTitle>{sanitizeDOMData(slogan?.content)}</HomeTitle>
    </Box>
  );
}

export default Slogan;
