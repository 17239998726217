import { useFetchEcompage } from "@/apis/queryFunctions/ecompagesFunctions";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";
import sanitizeDOMData from "@/utils/sanitizeDOMData";
import { Box, Grid, Text } from "@mantine/core";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

function HomeAbout() {
  const {locale} = useRouter()
  const { data } = useFetchEcompage("du-lieu-chung", locale);
  const info = data?.add_on_data?.[1] || {};

  return (
    <Box
      component="section"
    >
      <Grid align="center">
        <Grid.Col xs={12} md={6}>
          <Box>
            <Text align="justify">{sanitizeDOMData(info?.content)}</Text>
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              aspectRatio: info?.content_2 || "1",
            }}
          >
            <Image
              src={appendImageUrlFromAPI({ src: info?.picture, size: "l" })}
              layout="fill"
            />
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default HomeAbout;
